
import { Component, Vue, Inject, Watch } from "vue-property-decorator";
import { Toast } from "vant";

@Component
export default class Password extends Vue {
    @Inject() password!: string;
    @Inject() passwordInit!: () => void;
    private value = "";
    private showKeyboard = true;

    @Watch("value", { deep: true, immediate: true }) handler(val: string) {
        if (val.length === 6) {
            if (val === this.password) {
                window.sessionStorage.setItem("password", this.password);
                this.$router.push("/");
            } else {
                Toast.fail("密码错误");
            }
        }
    }

    created() {
        this.passwordInit();
    }
}
